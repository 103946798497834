import axios from "axios";

export default (endpoint, method, data, headers) =>
  axios({
    url: `${process.env.REACT_APP_API_BASE_URL}${endpoint}`,
    method,
    data,
    headers: {
      ...headers,
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
